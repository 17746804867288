<template>
<div>
<b-card title="Account">
    <b-card-text>
        {{owner}}
    </b-card-text>
        <b-card-text>
            SOL Balance: {{solbalance==0?0:solbalance.toFixed(4)}} SOL
    </b-card-text>
        <b-card-text>
        Rent Epoch: {{epoch}} 
    </b-card-text>

</b-card>
  <b-tabs content-class="mt-2" justified>
    <b-tab 
    title="Token Accounts">
    <b-card>
      <b-card-title>Token Accounts</b-card-title>
    <b-table
      :items="items"
      :fields="fields"
      bordered
      borderless
      hover
      responsive
       style="width: 100%;" fixed
    >
     <template #cell(tokenAccount)="data">
        <div class="w-100 text-truncate"   v-b-tooltip.hover.top="data.value"> {{data.value}}</div>
      </template>
     <template #cell(tokenIcon)="data">
        <b-avatar :src="data.value" />
        {{data.item.tokenName}} ({{data.item.tokenSymbol}})
      </template>
       <template #cell(tokenAmount.uiAmount)="data">
        {{data.value.toLocaleString("en-US")}}
      </template>
       <template #cell(value)="data">
        {{data.item.priceUsdt*data.item.tokenAmount.uiAmount}}
      </template>
    </b-table>
  </b-card>
    </b-tab>
    <b-tab
    active
      title="SPL Transfers History"
    >
        <b-card>
            <b-card-header>
                <div>
                  <b-card-title>SPL Transfers History</b-card-title> 
                  </div>   
                         <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
          <flat-pickr
          v-model="rangeDate"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
          :config="{ mode: 'range'}"
        />
      </div>
            </b-card-header> 
            <b-card-body>    
      <b-table
      :busy="isBusy"
      :items="itemsplh"
      :fields="fieldsplh"
      borderless
      bordered
      responsive
      hover
    style="width: 100%;" fixed
    >
     <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
     <template #cell(signature[0])="data">
        <div class="w-100 text-truncate"   v-b-tooltip.hover.top="data.value"> {{data.value}}</div>
      </template>
      <template #cell(address)="data">
        <div class="w-100 text-truncate"  v-b-tooltip.hover.top="data.value"> {{data.value}}</div>
      </template>
        <template #cell(slot)="data">
       #{{data.value}}
      </template>
         <template #cell(blockTime)="data">
        {{convertToDate(data.value)}}
      </template>
       <template #cell(changeAmount)="data">
           <h6 v-if="data.value>0" class="text-success">
        +{{(data.value/Math.pow(10,data.item.decimals)).toFixed(4)}}</h6>
        <h6 v-else class="text-danger">
        {{(data.value/Math.pow(10,data.item.decimals)).toFixed(4)}}</h6>
      </template>
      <template #cell(symbol)="data">
          <b-avatar :src="data.item.dicon" size="21"/>
          {{data.item.dname}} ({{data.item.symbol}})           
      </template>
      </b-table>
        <b-button
      size="md"
      class="mr-1"
      variant="primary"
      @click="getSplTransfersHistory"
      v-if="offsetspl!=-1"
    >
    See More Data
    </b-button>
      </b-card-body>
        </b-card>
    </b-tab>
      <b-tab
      title="SPL Transfers"
    >
        <b-card>
            <b-card-header>
                <div>
                  <b-card-title>SPL Transfers</b-card-title> 
                  </div>   
            </b-card-header> 
            <b-card-body>    
      <b-table
      :items="itemspl"
      :fields="fieldspl"
      borderless
      bordered
      responsive
      hover
    style="width: 100%;" fixed
    >
     <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
     <template #cell(signature[0])="data">
        <div class="w-100 text-truncate"   v-b-tooltip.hover.top="data.value"> {{data.value}}</div>
      </template>
      <template #cell(address)="data">
        <div class="w-100 text-truncate"  v-b-tooltip.hover.top="data.value"> {{data.value}}</div>
      </template>
        <template #cell(slot)="data">
       #{{data.value}}
      </template>
         <template #cell(blockTime)="data">
        {{convertToDate(data.value)}}
      </template>
       <template #cell(changeAmount)="data">
           <h6 v-if="data.value>0" class="text-success">
        +{{(data.value/Math.pow(10,data.item.decimals)).toFixed(4)}}</h6>
        <h6 v-else class="text-danger">
        {{(data.value/Math.pow(10,data.item.decimals)).toFixed(4)}}</h6>
      </template>
      <template #cell(symbol)="data">
          <b-avatar :src="data.item.dicon" size="21"/>
          {{data.item.dname}} ({{data.item.symbol}})           
      </template>
      </b-table>
      </b-card-body>
        </b-card>
    </b-tab>
  </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BCardText,BRow,BCol,BTable,BCard,BCardTitle,BAvatar ,VBTooltip,BCardHeader,BSpinner,
BCardBody,BButton} from 'bootstrap-vue'
import GraphicsAnalitys from './GraphicsAnalitys.vue';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive'
import {LAMPORTS_PER_SOL} from "@solana/web3.js";
const currentday= new Date().toISOString().substring(0, 10);
export default {
  components: {
    BCardText,
    BTabs,
    BTab,BRow,BCol,BTable,BCard,BCardTitle,BAvatar,BCardHeader,flatPickr,BCardBody,BSpinner,BButton,
    GraphicsAnalitys
  },
    directives: {
     'b-tooltip': VBTooltip,
    Ripple,
  },
  data(){
      let currentUser =JSON.parse(localStorage.getItem("dataAcc"));
      console.log(currentUser);
    return{
        owner:currentUser.owner,
         fields: [{ key: 'tokenAccount', label: 'Account' ,thStyle: {width: '15%'} },
         { key: 'lamports', label: 'SOL Balance' ,thStyle: {width: '15%'} },
         { key: 'tokenIcon', label: 'Token',thStyle: {width: '35%'}}
         ,{ key: 'tokenAmount.uiAmount', label: 'Token Balance',thStyle: {width: '15%'}},
         { key: 'value', label: 'Value'}],
      items:[],
      fieldsplh:[{label:'Signature',key:'signature[0]',
      thStyle: {width: '15%'} },
      {label:'Block',key:'slot',thStyle: {width: '15%'}},
      {label:'Time',key:'blockTime',thStyle: {width: '20%'}},
      {label:'Token Account',key:'address',thStyle: {width: '15%'} },
      {label:'Change Amount',key:'changeAmount',thStyle: {width: '15%'}},{label:'Token',key:'symbol'}],
      itemsplh:[],
       fieldspl:[{label:'Signature',key:'signature[0]',
      thStyle: {width: '15%'} },
      {label:'Block',key:'slot',thStyle: {width: '15%'}},
      {label:'Time',key:'blockTime',thStyle: {width: '20%'}},
      {label:'Token Account',key:'address',thStyle: {width: '15%'} },
      {label:'Change Amount',key:'changeAmount',thStyle: {width: '15%'}},{label:'Token',key:'symbol'}],
      itemspl:[],
      rangeDate:"2022-01-28 to "+currentday,
      solbalance:0,
      epoch:0,
      offsetspl:0,
      isBusy: false,
    }
  },
  mounted(){
    this.getTokenAccount();
    this.getAccountData();
    this.getSplTransfersHistory();
    this.getSplTransfers();
  },
  watch:{

      rangeDate:function(){
          this.itemspl=[];
         this.getSplTransfersHistory();
      }
  },
  methods:{
        getTokenAccount:function() {
      const vm=this;
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.get("https://public-api.solscan.io/account/tokens?account="+vm.owner+"",config) .then(function (response) {
   vm.items=response.data;
    })
    .catch((err) => {
      console.log(err);
    });
    },
     getSplTransfersHistory:async function() {
      const vm=this;
      const f=vm.rangeDate.split("to");
      if (f.length==2) { 
          var fromdate=new Date(f[0].trim());
          var todate=new Date(f[1].trim());
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  var ddata=[];
  this.isBusy = !this.isBusy
  const response=await axios.get("https://public-api.solscan.io/account/splTransfers?account="+vm.owner+"&fromTime="+(Math.floor(fromdate.getTime()/1000))+"&toTime="
  +(Math.floor(todate.getTime()/1000))+"&offset="+vm.offsetspl+"&limit=50",config) 
   if(response.data.data.length>0){
   for (let x = 0; x < response.data.data.length; x++) {
       if(response.data.data[x].symbol=="USDC" || response.data.data[x].symbol=="USDT"){
           ddata.push(response.data.data[x]);
       }
   }
    await vm.getTokenData(ddata);
    for (let x = 0; x < ddata.length; x++) {
         vm.itemsplh.push(ddata[x]);    
    }
    vm.offsetspl+=50;
    console.log(vm.offsetspl);
    console.log(vm.itemsplh);
    vm.isBusy = false
    vm.$forceUpdate();
    }
    else{
      vm.offsetspl=-1;
    }

    }
    },
      getSplTransfers:async function() {
       //this.offsetspl=0;
      const vm=this;
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  var cambio=true;
  var ddata=[];
  //this.isBusy = !this.isBusy
  const response=await axios.get("https://public-api.solscan.io/account/splTransfers?account="+vm.owner+"&limit=10",config) 
    ddata=response.data.data;
   await vm.getTokenData(ddata);
    //vm.offsetspl+=10;
      vm.itemspl=ddata;
    console.log(vm.itemspl);
    //vm.isBusy = false
      //cambio=false;
    },
    getAccountData:async function(){
        const vm=this;
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.get("https://public-api.solscan.io/account/"+vm.owner+"",config) .then(function (response) {
      if (response.data.lamports) {
        vm.solbalance=(response.data.lamports)/LAMPORTS_PER_SOL;
      }

      if (response.data.rentEpoch) {
            vm.epoch=response.data.rentEpoch;  
      }

    })
    .catch((err) => {
      console.log(err);
    });
    },
    getTokenData:async function(array){
          const vm=this;
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  for (let x = 0; x < array.length; x++) {
      const t = array[x].tokenAddress;
        const dx= await axios.get("https://public-api.solscan.io/token/meta?tokenAddress="+t+"",config);
        array[x]['dicon']=dx.data.icon;
        array[x]['dname']=dx.data.name;
  }
    }, 
     convertToDate:function(f){

const milliseconds = f * 1000
const dateObject = new Date(milliseconds)
const datex=new Date(Date.UTC(dateObject.getUTCFullYear(),dateObject.getUTCMonth(),dateObject.getUTCDate(),dateObject.getUTCHours(),dateObject.getMinutes(),dateObject.getUTCSeconds()))
const dateD=new Date(datex.toUTCString().substr(0, 25))
const humanDateFormat = dateD.toLocaleString() //2019-12-9 10:30:15
    return humanDateFormat;
    },
         numberWithCommas:function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>